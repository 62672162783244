* {
    margin: 0;
    padding: 0;
}

html {
    background: #1e1a20;
}

html,
body {
    height: 100vh;
    font-family: 'Poppins';

}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}


section {
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}

section:nth-child(even) {
    justify-content: flex-end;
}

.creativeText {
    transition: color ease-in-out 2.5s;
    padding: 5vmin;
}

.image {
    z-index: 256;
    position: absolute;
    top: 0;
    left: 0;
}